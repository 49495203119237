import React, { useState, useContext } from "react"

import Layout from "../components/customlayout"
import WelcomeHero from "../components/welcome"


const IndexPage = () => {
  return (
    <Layout>
      <WelcomeHero />
    </Layout>
  )
}
export default IndexPage